.logo-desktop a {
  max-width: 350px;
  padding: 2rem;
  margin-left: -1rem;
}

header {
}

.logo {
  padding: 1rem 0;
}

.logo img {
  width: 65vw;
  max-width: 300px;
  height: auto;
}

header .menu {
  text-transform: uppercase;
  font-weight: 700;

  a {
    color: $dark-gray;

    &:hover {
      color: $primary-color;
    }
  }

  a.button {
    color: $white;
    margin-top: 0.5rem;

    @include breakpoint(large) {
      margin-left: 1rem;
      margin-top: 0;
    }
  }

  &:hover {
    color: $light-gray;
  }
}

.logo-desktop {
  display: none;

  @include breakpoint(large) {
    display: block;
  }
}

#mainMenu {
  display: none;
  background: $light-gray;
  margin-left: -2rem;
  margin-right: -2rem;

  @include breakpoint(large) {
    display: flex;
    background: white;
    margin-left: 0;
    margin-right: 0;
  }
}

#mainMenu.expanded {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

#mobileNav {
  display: none;
  background-color: $light-gray;
  padding: 2rem 0;
  margin-bottom: 0.625rem;

  .submenu a {
    font-weight: normal;
    font-size: 0.9rem;
  }
}

#mobileNav.expanded {
  display: block;
}

.desktop-nav .submenu a {
  text-transform: capitalize;
}

#mainMenu.dropdown.menu > li > a {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

#mainMenu.dropdown.menu > li.logo-desktop > a {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.mobile-menu {
    padding: 0 1rem;
}
