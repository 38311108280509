.hero {
  max-height: 50vh;
  display: flex;
  position: relative;
  overflow: hidden;
  margin-bottom: 2rem;

  img {
    object-fit: cover;
    object-position: center;
    min-width: 100%;
  }

  .text-block {
    // border-top: 1px solid $primary-color;
    position: absolute;
    // bottom: 20%;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // background-color: rgba(0, 0, 0, 0.6);

    // background: linear-gradient(
    //   0.25turn,
    //   rgba(0, 0, 0, 0),
    //   rgba(0, 0, 0, 0.6),
    //   rgba(0, 0, 0, 0.6),
    //   rgba(0, 0, 0, 0.6),
    //   rgba(0, 0, 0, 0.6),
    //   rgba(0, 0, 0, 0.6),
    //   rgba(0, 0, 0, 0)
    // );
    // background-color: rgba(255, 255, 255, 0.85);
    color: white;
    // color: $black;
    padding: 1rem 4rem;
    text-align: center;

    p {
      text-shadow: 0px 0px 20px #000000;
    }

    @include breakpoint(large) {
      h1 {
        font-size: 3rem;
      }
    }
  }
}

.home {
  .hero {
      max-height: 85vh;
  }
}

.photo-credit {
  position: absolute;
  bottom: -3rem;
  left: 20px;
  font-style: italic;
  color: $dark-gray;
}

.overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
}
