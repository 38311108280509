h3 a {
  font-weight: bold;
}

footer {
  background: #545454;

  color: white;
  min-height: 300px;
  padding: 3rem 0 3rem 0;
  color: $white;

  h3 {
    font-size: 1.3rem;
  }

  @include breakpoint(medium) {
    padding: 6rem 0 3rem 0;
  }

  a {
    color: $white;
  }

  .menu a,
  .menu .button {
    line-height: 1.4;
  }

  .menu p {
    margin-bottom: 0;
  }
}

.copyright {
  font-size: 0.8rem;
  margin-top: 6rem;
  text-align: center;
}

.social a {
  font-size: 2rem;
  color: $primary-color;
}
