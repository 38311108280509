@charset 'utf-8';

@import "settings";
@import "node_modules/foundation-sites/assets/foundation";
@import "node_modules/motion-ui/motion-ui";

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;

// Components
@import "components/header";
@import "components/hero";
@import "components/footer";

$primary-color: #ccc;

h1 {
  color: $primary-color;
}

figure {
  margin: 1rem 0;
}

figcaption {
  margin-top: 0.5rem;
  font-size: 0.95rem;
  color: $dark-gray;
  font-style: italic;
}

// PAGES

main {
  padding-top: 1rem;
  padding-bottom: 3rem;
}

// -- Image
@include breakpoint(medium) {
  .image-right {
    float: right;
    margin-left: 1rem;
    margin-bottom: 1rem;
    margin-top: 0;
  }

  .image-left {
    float: left;
    margin-right: 1rem;
    margin-bottom: 1rem;
    margin-top: 0;
  }

  .image-small {
    width: 25%;
  }

  .image-medium {
    width: 33%;
  }

  .image-large {
    width: 50%;
  }

  .image-full {
    width: 100%;
  }
}


// Banner

.banner {

.big-pic {
    min-height: 60vh;
}

}

.header-image {

}

.caption {
    text-align: right;
    .lead {
        color: white;
    }
}


blockquote {
    clear: both;
    background-color: rgba(0,0,0,0.01);
    color: $dark-gray;
    font-style: italic;
    font-size: 1.8rem;
    border: 1px solid $light-gray;
    padding: 2rem 2rem 1rem;
    margin: 2rem 0;
    text-align: center;
    font-family: 'Playfair Display', Georgia, 'Times New Roman', Times, serif;
}
.cta {
    background-color: $light-gold;
    padding: 3rem 0;
    text-align: center;
    font-size: 1.2rem;

    a.button {
        margin-top: 1rem;
    }
}

footer .menu .menu-text {
    line-height: 1.6;
    font-weight: normal;
}
